@import '../../assets/styles/index.scss';

.layout {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  .main {
    width: 100%;
    margin: 32px 0;
    overflow: hidden;
  }
}
