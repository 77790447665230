@import '../../assets/styles/index.scss';

.inputContainer {
  display: flex;
  flex-direction: column;

  &.error {
    .passwordInputContainer {
      border: 1px solid #d20000;
    }
  }
}

.label {
  margin-bottom: 8px;
  color: var(--text, #1e1a26);
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.passwordInputContainer {
  flex: 1;
  padding: 0px 16px;
  border-radius: 16px;
  border: 0.5px solid #d6d1dc;
  background: #fff;
  color: var(--text, #1e1a26);
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  display: flex;
  justify-content: space-between;
}

.input {
  flex: 1;

  padding: 18px 0px;
}

.togglePasswordButton {
  background: none;
  border: none;
  cursor: pointer;
}
