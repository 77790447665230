@import '../../assets/styles/index.scss';

.task {
  .main {
    width: 100%;
  }

  .title {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 32px;
  }

  .content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .selectors {
      margin-top: 5px;
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .btn {
      margin-top: 24px;
      background-color: #ff385c;
      padding: 14px 20px;
      display: flex;
      gap: 10px;
      color: white;
      align-items: center;
      display: inline-flex;
      padding: 14px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #fff;
      font-family: Karla;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 125% */
      border-radius: 5px;
      transition: 0.4s;

      &:active {
        background: white;
        color: #ff385c;
        outline: 1px solid #ff385c;

        .svg {
          fill: #ff385c;
        }
      }
    }
  }
}
