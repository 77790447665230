@import '../../assets/styles/index.scss';

.container {
  max-width: 1130px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  @media (max-width: 400px) {
    padding: 0 10px;
  }
}
