@import '../../assets/styles/index.scss';

.step {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 24px;
  border-radius: 16px 16px 0px 0px;
  background: #f5f5ff;
  border: 0.5px solid #e2dbea;
  border-bottom: 0;

  .description {
    color: #877ba0;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    @media (max-width: 520px) {
      flex-direction: column-reverse;

      .title {
        margin-right: auto;
      }

      .step_number {
        margin-left: auto;
      }
    }

    .title {
      color: var(--text, #1e1a26);
      font-family: Rubik;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .step_number {
      color: #683de4;

      span {
        margin-left: 2px;
        color: #877ba0;
      }
    }
  }

  .nextButton {
    background-color: #683de4;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
      background-color: #896be2;
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}

.lineWrapper {
  height: 7px;
  background: #f5f5ff;
  position: relative;
  border-bottom: 0.5px solid #e2dbea;
  border-left: 0.5px solid #e2dbea;
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;

  .line {
    height: 100%;
    background: linear-gradient(90deg, #896be2 0%, #fe7c93 100%);
    transition: width 0.3s ease-in-out;
  }
}
