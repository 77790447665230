@import '../../assets/styles/index.scss';

.tooltipContainer {
  position: relative;
}

.tooltipTrigger {
  cursor: pointer;
  color: #ff385c;
  padding-right: 100px;
  z-index: 100;
  white-space: nowrap;
}

.tooltipText {
  position: absolute;
  top: 50%;
  left: 60%;
  padding: 15px;
  width: 300px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(26, 5, 15, 0.12);
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
  z-index: 100;
  transform: translateY(-50%);
}

.tooltipText::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  border-width: 10px;
  border-style: solid;
  border-color: transparent white transparent transparent;
  transform: translateY(-50%);
  z-index: 2;
}

.tooltipTrigger:hover + .tooltipText {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 760px) {
  .tooltipText {
    position: absolute;
    top: 180%;
    left: 50%;
    z-index: 100;
    transform: translateX(-50%);
  }
  .tooltipText::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    border-width: 10px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    transform: translateX(-50%) rotate(180deg);
    z-index: 2;
  }
}
