* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

body {
  background-color: #f9f9fc;
  font-size: 16px;
  font-family: Karla;
  line-height: normal;
  font-family: 'Karla', sans-serif;
  color: var(--text, #1e1a26);
  white-space: normal;
}

button {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.4s;
}

h1 {
  font-size: 25px;
}

h2 {
  font-size: 20px;
}

a {
  text-decoration: none;
  outline: none;
  border: none;
  color: var(--text, #1e1a26);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0;
  margin: 0;
}

input::placeholder,
textarea::placeholder {
  color: var(--text-light, rgba(30, 26, 38, 0.5));
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

@media (max-width: 375px) {
  body {
    width: 375px;
  }
}
