.remove {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 40px;
    .text {
        font-size: 20px;
    }
  .controls {
      display: flex;
      margin-top: 20px;
      gap: 30px;
      .cancel {
          background-color: #ffffff;
          border: 1px solid gray;
          padding: 14px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Karla;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          border-radius: 5px;
          transition: 0.4s;
      }
      .action {
          background-color: #ff385c;
          padding: 14px 20px;
          display: flex;
          color: white;
          align-items: center;
          justify-content: center;
          font-family: Karla;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          border-radius: 5px;
          transition: 0.4s;
      }
  }
}