.progress-circle {
  width: 150px;
  height: 150px;
  position: relative;
}

.circle-background {
  fill: none;
  stroke: linear-gradient(90deg, #896be2 0%, #fe7c93 100%);
  /* Установка градиента */
  stroke-width: 5;
  stroke-dasharray: 565.48;
  r: 40;
  cx: 50;
  cy: 50;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.circle-progress {
  fill: none;
  stroke-width: 1;
  stroke-dasharray: 565.48;
  r: 40;
  cx: 50;
  cy: 50;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 1s ease-in-out;
}

.circle-text {
  color: var(--text, #1e1a26);
  text-align: center;
  font-family: Rubik;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.progress-circle {
  width: 150px;
  height: 150px;
  position: relative;
  stroke-linecap: round;
}

.circle-background {
  fill: none;
  stroke: rgba(226, 222, 234, 0.6);
  stroke-width: 5;
  stroke-dasharray: 565.48;
  r: 40;
  cx: 50;
  cy: 50;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-linecap: round;
}

.circle-progress {
  fill: none;
  stroke-width: 5;
  stroke-dasharray: 565.48;
  r: 40;
  cx: 50;
  cy: 50;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 1s ease-in-out;
}

@media (max-width: 760px) {
  .progress-circle {
    width: 100px;
    height: 100px;

    svg {
      width: 100px;
      height: 100px;
    }
  }

  .circle-text {
    font-size: 36px;
  }
}
