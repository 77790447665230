@import '../../assets/styles/index.scss';

.ToDoItem {
  z-index: 10;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  cursor: pointer;
  background-color: white;
  height: 200px;
  transition: 0.3s;
  margin: 0;
  box-shadow: 0px 4px 30px 0px rgba(26, 19, 52, 0.04);

  &:hover {
    box-shadow: 0px 4px 30px 0px rgba(26, 5, 15, 0.12);

    .content {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 0 24px;
      padding-top: 12px;
      padding-bottom: 16px;
      background: #ff385c;
      transition: 0.3s;
      border-radius: 10px 10px 0px 0;
      cursor: pointer;
      margin: 0;

      .img {
        width: 36px;
        height: 36px;
        padding: 6px;
        border-radius: 8px;
        background: rgba(255, 233, 236, 0.4);
        margin: 0;

        img {
          width: 100%;
        }
      }

      .text {
        margin: 0;

        transition: 0.3s;
      }

      .title {
        overflow: hidden;

        color: #fff;
        text-overflow: ellipsis;
        font-family: Karla;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        text-transform: capitalize;
      }

      .description {
        color: rgba(255, 255, 255, 0.7);
        font-family: Karla;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-transform: capitalize;
        margin: 0;
        padding: 0;
      }
    }

    .pro {
      background: #325467;
    }

    .content_hidden {
      padding: 0 24px;

      padding-top: 16px;
      padding-bottom: 24px;

      display: flex;

      .right,
      .left {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .block {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .block_info {
          color: var(--text, #1e1a26);
          font-family: Karla;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .block_text {
          color: rgba(50, 84, 103, 0.5);
          font-family: Karla;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    transition: 0.3s;
    cursor: pointer;

    .text {
      margin-top: 13px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      transition: 0.3s;
    }

    .img {
      width: 48px;
      height: 48px;
      padding: 8px;
      border-radius: 8px;
      background: #ffe9ec;

      img {
        width: 100%;
      }
    }

    .title {
      color: var(--text, #1e1a26);
      font-family: Karla;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      /* 120% */
      text-transform: capitalize;
    }

    .description {
      color: rgba(83, 81, 116, 0.5);
      font-family: Karla;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 125% */
      text-transform: capitalize;
      padding-bottom: 30px;
    }

    .pro_hovered {
      display: flex;
      align-items: center;
      gap: 10px;
      color: white;
      margin-left: auto;
    }

    .img_box {
      display: flex;
      justify-content: space-between;

      .pro {
        display: flex;
        align-items: center;
        gap: 10px;
        color: #fbbc05;
        margin-left: auto;
      }
    }
  }

  .content_hidden {
    display: flex;

    display: none;
    padding: 16px 24px;

    .right,
    .left {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .block {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .block_info {
        font-weight: bold;
      }

      .block_text {
        color: rgba(50, 84, 103, 0.5);
      }
    }
  }
}

.blured {
  filter: blur(8px);
}
.todo_wrapper {
  position: relative;
}

.soon {
  position: absolute;
  left: 25%;
  top: 40%;
  z-index: 50;
  font-size: 30px;
  font-weight: 600;
}

.hidden {
  display: none;
}

.soon_text {
  font-size: 20px;
  text-align: center;
}
