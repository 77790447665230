@import '../../assets/styles/index.scss';

.ToDoItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  width: 100%;
  border-top: 0.5px solid #d6d1dc;

  .content {
    display: flex;
    gap: 24px;
    width: 100%;
    align-items: stretch;

    @media (max-width: 700px) {
      gap: 16px;
    }

    .colomn {
      padding: 20px 0;
      width: 100% !important;
      gap: 16px;
      display: flex;
      justify-content: space-between;

      .text {
        display: flex;
        gap: 16px;
      }

      @media (max-width: 700px) {
        flex-direction: column;

        .buttons {
          margin-left: auto;
        }
      }
    }
  }

  &.completed {
    .content {
      .icon_container {
        opacity: 0.4;
        background: #ffe9ec;
      }

      .icon {
        .date {
          background: rgba(255, 56, 92, 0.3);
        }
      }
    }

    background-color: rgb(251, 251, 253) !important;

    .description {
      .title {
        text-decoration: line-through;
        color: var(--text, #1e1a26);
        font-family: Karla;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        opacity: 0.4;
        text-decoration-line: strikethrough;
      }

      .times {
        p {
          color: var(--text-light, rgba(30, 26, 38, 0.5));
          font-family: Karla;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }

  .description {
    display: flex;
    gap: 5px;
    flex-direction: column;

    .title {
      color: var(--text, #1e1a26);
      font-family: Karla;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .times {
      color: var(--text-light, rgba(30, 26, 38, 0.5));
      display: flex;
      align-items: center;
      gap: 5px;
      color: var(--text-light, rgba(30, 26, 38, 0.5));
      font-family: Karla;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .icon_container {
    border-radius: 8px;
    height: 40px;
    width: 40px;

    border-radius: 8px;
    background: #ffe9ec;

    padding: 8px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .icon {
    width: 100%;
    max-width: 70px;

    .date {
      background-color: #ff385c;
      padding: 16px;
      justify-content: center;
      align-items: center;
      display: flex;
      color: white;
      text-align: center;
      align-items: center;
      gap: 20px;
      color: rgba(255, 255, 255, 0.9);
      font-family: Karla;
      font-size: 28px;

      span {
        font-size: 14px;
      }

      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      height: 100%;
    }

    .date {
      display: flex;
      flex-direction: column;
      gap: 0;
      align-items: center;

      .date_info {
        font-weight: bold;
      }

      .date_start {
        color: gray;
      }
    }

    .icon_content {
      padding: 25px 20px;
      border-radius: 20px 0px 0px 20px;
      background-color: #ff385c;
    }
  }

  .description {
    font-weight: bold;
  }

  .count_task {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .count {
      font-weight: bold;
    }

    .task {
      color: gray;
    }
  }

  .buttons {
    padding-right: 16px;
    display: flex;
    gap: 16px;
    align-items: center;

    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateY(10px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .completed_block {
      display: flex;
      align-items: center;
      gap: 10px;
      animation: fadeIn 0.5s ease;

      p {
        color: #0091e2;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 125% */
      }
    }

    .edit {
      padding: 8px 16px;
      display: flex;
      gap: 8px;
      align-items: center;
      color: #ff385c;
      border: 1px solid #ff385c;
      color: var(--pr, #ff385c);
      font-family: Karla;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 125% */
    }
  }
}
