.textTruncate {
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;

  &.mobile {
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    overflow-wrap: break-word;
  }
}
