@import '../../assets/styles/index.scss';

.footer {
  margin-top: auto;
  border-top: 1px solid #d6d1dc;
  background-color: white;

  .content {
    padding: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      color: var(--text-light, rgba(30, 26, 38, 0.5));
      text-align: center;
      font-family: Karla;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 185.714% */
    }
  }
}
