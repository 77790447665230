@import '../../assets/styles/index.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s;
  backdrop-filter: blur(5px);
  z-index: 111000;
  padding: 0 20px;
  overflow: auto;

  &.hidden {
    animation: fadeOut 0.3s;
    pointer-events: none;
    opacity: 0;
  }

  .modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 10px;
  }

  .modal-content {
    max-height: 95vh;

    margin: 0 auto;
    background-position: left;
    background-size: contain;
    background-size: 100%;
    background-color: white;

    width: 100%;
    min-height: 220px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    position: relative;
    overflow-y: auto;

    .close-button {
      position: absolute;
      right: 10px;
      top: 10px;
      border: none;
      background: none;
      width: 25px;
      height: 25px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin-left: auto;
      cursor: pointer;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
