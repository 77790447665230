.icons {
  display: flex;
  gap: 16px;
  align-items: center;

  .icon {
    border-radius: 26px;
    border: 0.5px solid #d8dadc;
    background: #fff;
    padding: 16px;
    cursor: pointer;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 900px) {
  .icons {
    display: flex;
    gap: 10px;
    align-items: center;

    .icon {
      border-radius: 26px;
      border: 0.5px solid #d8dadc;
      background: #fff;
      padding: 16px;
      cursor: pointer;
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
