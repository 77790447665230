@import '../../assets/styles/index.scss';

.unfinishedTasks {
  .loadMore {
    margin: 20px auto;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #ff385c;
    padding: 14px 20px;
    color: white;
  }

  .up {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 500px) {
      align-items: flex-start;
      gap: 24px;
      flex-direction: column;
    }

    .title {
      color: var(--text, #1e1a26);
      font-family: Rubik;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 900px) {
      align-items: center;
      flex-direction: column;
    }
  }

  .btn {
    background-color: #ff385c;
    padding: 14px 20px;
    display: flex;
    gap: 10px;
    color: white;
    align-items: center;
    display: inline-flex;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
    border-radius: 5px;
    transition: 0.4s;

    &:active {
      background: white;
      color: #ff385c;
      outline: 1px solid #ff385c;

      .svg {
        fill: #ff385c;
      }
    }
  }

  .down {
    margin-top: 40px;

    .title {
      margin-top: 60px;
      margin-bottom: 30px;
    }

    .tasks {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .finished_tasks {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
