@import '../../assets/styles/index.scss';

.header {
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #d6d1dc;

  .content {
    padding: 12px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    font-weight: bold;

    .logout {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--text, #1e1a26);
      font-family: Karla;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &:hover {
        color: #ff385c;
      }
    }

    .left {
      display: flex;
      align-items: center;
      gap: 50px;

      @media (max-width: 360px) {
        gap: 10px;
      }
    }

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      gap: 5px;
    }

    .item {
      display: flex;
      gap: 5px;
      cursor: pointer;
      align-items: center;
      color: var(--pr, #ff385c);
      font-family: Karla;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      transition: 0.3s;

      &:hover {
        p {
          color: var(--pr, #ff385c);
          font-weight: 700;
        }
      }

      &__text_act {
        color: var(--pr, #ff385c);
        font-weight: 700;
      }

      &__text {
        color: var(--text, #1e1a26);
        font-weight: 500;
      }
    }
  }
}
