@import '../../assets/styles/index.scss';

.template {
  .title {
    margin-top: 10px;
    text-align: center;
    margin-bottom: 32px;
  }

  .btn {
    margin: 20px auto;
    margin-top: 24px;
    background-color: #ff385c;
    padding: 14px 20px;
    display: flex;
    gap: 10px;
    color: white;
    align-items: center;
    display: inline-flex;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 5px;
    transition: 0.4s;

    &:active {
      background: white;
      color: #ff385c;
      outline: 1px solid #ff385c;

      .svg {
        fill: #ff385c;
      }
    }
  }

  .content {
    padding: 10px;
    display: flex;
    width: 100%;

    gap: 30px;

    .left,
    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    @media (max-width: 570px) {
      flex-direction: column;

      .left,
      .right {
        width: 100%;
      }
    }
  }

  .check {
    display: flex;
    align-items: center;
    margin: 5px auto;

    input {
      width: 30px;
    }
  }
}
