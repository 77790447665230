.timeChoice {
  font-size: 16px;
  padding: 8px;
  margin-right: 10px;
}

// Добавляем стили для селекта
.selectWrapper {
  position: relative;
}

.timeChoiceSelect {
  border: 1px solid #d6d1dc;
  border-radius: 8px;
  width: 120px;
  padding: 8px;
  font-size: 14px;
}

// Стили для опций
.option {
  font-size: 14px;
}
