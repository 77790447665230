@import '../../assets/styles/index.scss';

.accordion {
  overflow: hidden;
  border-radius: 16px;
  border-bottom: 1px solid #eee;
  background: #fff;

  &-header {
    padding: 20px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .right {
      display: flex;
      gap: 16px;
      align-items: center;
      color: #ff385c;

      .tasks {
        color: var(--pr, #ff385c);
        font-family: Karla;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      gap: 2px;

      h1 {
        color: var(--text, #1e1a26);
        font-family: Karla;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: var(--text-light, rgba(30, 26, 38, 0.5));
        font-family: Karla;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  &-title {
    font-size: 18px;
    margin: 0;
  }

  &-content-enter,
  &-content-exit {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition:
      opacity 300ms ease-in,
      max-height 300ms ease-in;
  }

  &-content-enter-active,
  &-content-exit-active {
    opacity: 1;
    max-height: 1000px;
    transition:
      opacity 300ms ease-out,
      max-height 300ms ease-out;
  }
}
