@import '../../assets/styles/index.scss';

.layout {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .main {
    width: 100%;
    margin: 24px 0;
  }
}
