.text {
  padding: 15px 0;
  overflow-wrap: break-word;
}

.title {
  color: var(--text, #1e1a26);
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  padding: 15px 0;
}

.link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #0056b3;
    text-decoration: none;
  }
}