@import '../../assets/styles/index.scss';

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.error {
    .input {
      border: 1px solid #d20000;
    }
  }

  .label {
    color: var(--text, #1e1a26);
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
  }

  .input {
    border-radius: 16px;
    border: 0.5px solid #d6d1dc;
    background: #fff;
    padding: 18px 16px;
    outline: none;

    ::placeholder {
      color: var(--text-light, rgba(30, 26, 38, 0.5));
      font-family: Karla;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 125% */
    }
  }

  .message {
    font-size: 12px;
    color: #d20000;
    margin-left: 20px;
  }
}
