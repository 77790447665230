.empty {
  margin-top: 40px;
  margin-bottom: 30px;

  border-radius: 16px;
  border: 0.5px solid #e2dbea;
  padding: 32px;

  @media (max-width: 700px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    padding: 24px;
  }

  background: #f5f5ff;
  color: var(--text, #1e1a26);
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 605px;
  width: 100%;

  a {
    color: var(--pr, #ff385c);
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
