@font-face {
  font-family: 'Karla';
  src: url('./Fonts/Karla/Karla/static/Karla-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./Fonts/Rubik/Rubik/static/Rubik-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
