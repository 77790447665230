@import '../../assets/styles/index.scss';

.main {
  margin-left: auto;
  max-width: 340px;
  width: 100%;

  @media (max-width: 760px) {
    margin: 0 auto;
  }
}

.datePicker {
  margin-left: auto;
  position: relative;
  display: block;
  max-width: 340px;
  width: 100%;

  .text {
    color: var(--text, #1e1a26);
    font-family: Karla;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
  }
}

input {
  width: 100%;
  padding: 5px;
  outline: none;
  border: none;
}

.selectedDate {
  cursor: pointer;
  padding: 16px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--text-light, rgba(30, 26, 38, 0.5));
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */

  &.error {
    border-radius: 16px;
    outline: 1px solid var(--pr, #ff385c);
    background: #fff;
    box-shadow: 0px 4px 30px 0px rgba(26, 19, 52, 0.04);
  }

  .inputError {
    color: #ff385c;
  }
}

.calendarContainer {
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;

  @media (max-width: 760px) {
    top: auto;
    bottom: 70%;
  }
}

.error {
  margin-top: 15px;
  color: #ff385c;
}
