@import '../../assets/styles/index.scss';

.template {
  margin-top: 20px;

  .loadMore {
    margin: 20px auto;
  }

  .tasks {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .next_task {
      width: 100%;
      padding: 24px 32px;
      display: flex;
      border-radius: 16px;

      background-color: white;
      flex-direction: column;
      gap: 16px;

      @media (max-width: 585px) {
        padding: 16px;
        flex-direction: column;
      }

      .down_text {
        display: flex;
        justify-content: space-between;

        a {
          align-items: center;
          align-items: center;
          gap: 10px;
          color: #683de4;
          display: flex;
          color: var(--sec, #683de4);
          font-family: Karla;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }

        .text {
          width: 100%;
          display: flex;

          justify-content: space-between;

          @media (max-width: 585px) {
            flex-direction: column;
          }

          .buttons {
            display: flex;
            gap: 24px;
            align-items: center;

            .edit {
              padding: 8px 16px;
              display: flex;
              gap: 8px;
              align-items: center;
              color: #ff385c;
              border: 1px solid #ff385c;
              color: var(--pr, #ff385c);
              font-family: Karla;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            }
          }

          .main {
            display: flex;
            gap: 20px;
            align-items: center;
          }

          .description {
            display: flex;
            flex-direction: column;

            gap: 4px;

            .title {
              color: #1e1a26;
              font-family: Karla;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .img {
            border-radius: 6px;
            background: #e0dbff;
            padding: 8px;

            img {
              width: 100%;
            }
          }

          .times {
            display: flex;
            color: #877ba0;
            gap: 2px;
            align-items: center;

            p {
              color: #877ba0;
              font-family: Karla;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }

        @media (max-width: 585px) {
          flex-direction: column;

          a {
            margin-left: auto;
          }

          .text {
            display: flex;
            gap: 20px;

            .description {
              display: flex;
              flex-direction: column;

              gap: 4px;
            }

            .img {
              margin-bottom: auto;
              border-radius: 6px;
              background: #e0dbff;
              padding: 8px;

              img {
                width: 100%;
              }
            }

            .times {
              display: flex;
              color: #877ba0;
              gap: 2px;
              align-items: center;

              p {
                color: #877ba0;
                font-family: Karla;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }
      }

      .up_text {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
          color: #1e1a26;
          font-family: Rubik;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .date {
          color: #1e1a26;
          text-align: right;
          font-family: Karla;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}
