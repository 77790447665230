@import '../../assets/styles/index.scss';

.calendar {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  width: 100%;
}

.date-picker {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;

  .buttons {
    display: flex;
    gap: 10px;
    margin-right: 15px;
  }
}

.date-picker select {
  margin-right: 10px;
  font-size: 14px;
  padding: 5px;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: white;

  border-radius: 4px;
}

.grayed {
  background-color: #ccc;
  /* или любой другой серый цвет */
  color: #666;
  /* цвет текста, который вы считаете подходящим */
  cursor: not-allowed;
  /* курсор указывает, что дата неактивна */
}

.date-picker button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.days-of-week {
  display: flex;
  justify-content: space-between;
  color: var(--text-light, rgba(30, 26, 38, 0.5));
  font-weight: bold;
  padding: 5px 0;
  font-size: 12px;
}

.day {
  flex: 1;
  text-align: center;
}

.calendar-dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;

  .today {
    border-radius: 8px;
    color: white !important;
    background: var(--pr, #ff385c) !important;
  }

  .disabled {
    color: #aaa;
  }
}

.date {
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 50%;
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.date.current-month {
  background-color: #fff;
}

.date.other-month {
  color: #aaa;
}

.date.selected {
  background-color: #e0dbff;
  color: #683de4;
}

@media (max-width: 400px) {
  .calendar {
    padding: 10px;
  }

  .date-picker {
    .buttons {
      display: flex;
      gap: 10px;
      margin-right: 15px;
    }
  }

  .date-picker select {
    margin-right: 10px;
    font-size: 14px;
    padding: 5px;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: white;

    border-radius: 4px;
  }

  .date-picker button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }

  .days-of-week {
    display: flex;
    justify-content: space-between;
    color: var(--text-light, rgba(30, 26, 38, 0.5));
    font-weight: bold;
    padding: 5px 0;
    font-size: 12px;
  }

  .day {
    flex: 1;
    text-align: center;
  }

  .calendar-dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;

    .today {
      border-radius: 8px;
      color: white !important;
      background: var(--pr, #ff385c) !important;
    }
  }

  .date {
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 50%;
    transition:
      background-color 0.3s,
      border-color 0.3s;
  }
}

@media (max-width: 370px) {
  .calendar {
    padding: 5px;
  }

  .date-picker {
    .buttons {
      display: flex;
      gap: 10px;
      margin-right: 5px;
    }
  }

  .date-picker select {
    margin-right: 0px;
    font-size: 14px;
    padding: 5px !important;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: white;

    border-radius: 4px;
  }

  .date-picker button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }

  .days-of-week {
    display: flex;
    justify-content: space-between;
    color: var(--text-light, rgba(30, 26, 38, 0.5));
    font-weight: bold;
    padding: 5px 0;
    font-size: 12px;
  }

  .day {
    flex: 1;
    text-align: center;
  }

  .calendar-dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1px;

    .today {
      border-radius: 8px;
      color: white !important;
      background: var(--pr, #ff385c) !important;
    }
  }

  .date {
    text-align: center;
    padding: 9px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 50%;
    transition:
      background-color 0.3s,
      border-color 0.3s;
  }
}
